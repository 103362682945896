import _ from ".";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var capability = _,
    define = capability.define,
    test = capability.test;
define("strict mode", function () {
  return (this || _global) === undefined;
});
define("arguments.callee.caller", function () {
  try {
    return function () {
      return arguments.callee.caller;
    }() === arguments.callee;
  } catch (strictModeIsEnforced) {
    return false;
  }
});
define("es5", function () {
  return test("Array.prototype.forEach") && test("Array.prototype.map") && test("Function.prototype.bind") && test("Object.create") && test("Object.defineProperties") && test("Object.defineProperty") && test("Object.prototype.hasOwnProperty");
});
define("Array.prototype.forEach", function () {
  return Array.prototype.forEach;
});
define("Array.prototype.map", function () {
  return Array.prototype.map;
});
define("Function.prototype.bind", function () {
  return Function.prototype.bind;
});
define("Object.create", function () {
  return Object.create;
});
define("Object.defineProperties", function () {
  return Object.defineProperties;
});
define("Object.defineProperty", function () {
  return Object.defineProperty;
});
define("Object.prototype.hasOwnProperty", function () {
  return Object.prototype.hasOwnProperty;
});
define("Error.captureStackTrace", function () {
  return Error.captureStackTrace;
});
define("Error.prototype.stack", function () {
  try {
    throw new Error();
  } catch (e) {
    return e.stack || e.stacktrace;
  }
});
export default {};