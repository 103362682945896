var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

var CapabilityDetector = function () {
  (this || _global).tests = {};
  (this || _global).cache = {};
};

CapabilityDetector.prototype = {
  constructor: CapabilityDetector,
  define: function (name, test) {
    if (typeof name != "string" || !(test instanceof Function)) throw new Error("Invalid capability definition.");
    if ((this || _global).tests[name]) throw new Error("Duplicated capability definition by \"" + name + "\".");
    (this || _global).tests[name] = test;
  },
  check: function (name) {
    if (!this.test(name)) throw new Error("The current environment does not support \"" + name + "\", therefore we cannot continue.");
  },
  test: function (name) {
    if ((this || _global).cache[name] !== undefined) return (this || _global).cache[name];
    if (!(this || _global).tests[name]) throw new Error("Unknown capability with name \"" + name + "\".");
    var test = (this || _global).tests[name];
    (this || _global).cache[name] = !!test();
    return (this || _global).cache[name];
  }
};
exports = CapabilityDetector;
export default exports;