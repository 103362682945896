import _CapabilityDetector from "./CapabilityDetector";
var exports = {};
var CapabilityDetector = _CapabilityDetector;
var detector = new CapabilityDetector();

var capability = function (name) {
  return detector.test(name);
};

capability.define = function (name, test) {
  detector.define(name, test);
};

capability.check = function (name) {
  detector.check(name);
};

capability.test = capability;
exports = capability;
export default exports;